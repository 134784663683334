@import 'Fonts';

.round-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &--icon-container {
    width: 48px;
    height: 48px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;

    svg {
      position: relative;
      .inverted & {
        path {
          stroke: #fff;
        }
      }
    }

    &--green-circle {
      position: absolute;
      width:0;
      height:0;
      border-radius: 50%;
      opacity: 0;
      background-color: $vinosGreen;
      // transition: width 0.2s ease-out 0, height 0.2s ease-out 0;
      
      transition: opacity 0.3s ease-out, width 0.2s ease-out 0.6s, height 0.2s ease-out 0.6s;
      
      .inverted & {
        opacity: 1;
        transition: opacity 0s ease-out, width 0.2s ease-out 0s, height 0.2s ease-out 0s;
        width:48px;
        height:48px;
      }
    } 
  }


  &--label {
    margin: 9px 0 0;
    @include BodySmaller;
  }
}