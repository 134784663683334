$WarmLightGray: #F4F2F0;
$WarmLightGray2: #F1EBEA;
$WarmDarkGray: #EAE6E1;
$border: #e1e1e1;
$pageBg: $WarmLightGray;
$primaryBg: #FF691E;
$primaryText: #fff;
$vinosMidPurple: #E95003;
$vinosLightPurple: #FFC3A5;
$vinosDeepPurple: #000;
$vinosGreen: #FF691E;
$vinosLightGreen: #30cc22;
$vinosDeepGreen: #45523D;
$textLight: #7D7D7D;
$textDark: #323232;

$orangeLight: #F2992F;
$orangeDark: #E65B22;

$primaryGradient: linear-gradient(180deg, $orangeLight 0%, $orangeDark 100%);

$buttonColor: #fff;
$buttonBg: $vinosMidPurple;


$redWine: #961e1e;
$whiteWine: #ffd700;
$roseWine: #ffc8af;
$sparklingWine: #ffd700;
$aperitifWine: #E97D4C;


$G50: #323232;
$G30: #1E1E1E;
$G125: #7D7D7D;
$G165: #A5A5A5;
$G200: #C8C8C8;
$G225: #E1E1E1;
$G245: #F5F5F5;

.color-g125 {
  color: $G125;
}
