.age-modal {
  background-color: #fff;
  border-radius: 5px;
  padding: 40px;
  text-align: center;

  p {
    margin-bottom: 29px;
  }

  button {
    max-width: 300px;
    margin: auto;
  }
}