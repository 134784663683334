@import './Colors.scss';
.like-button {

    &--icon {
        position: relative;
        width:24px;
        height:24px;
        // border: 2px solid $vinosMidPurple;
        transform: translate3d(0%,0%,0);
        pointer-events: none;
        
        

        &--heart {
            svg {
               
                path {
                    fill:#fff;
                    transition: all 0.3s;

                    .selected & {
                        fill: #E95003;
                    }
                }
                
            }
        }

        &--heart-big {
            position: absolute;
            top: 0;
            opacity: 0;
        }
        
        
    }

    
}