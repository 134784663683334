@import './Colors.scss';
@import './Fonts.scss';

.add-edit-wine-list-modal {
  background-color: $WarmLightGray;
  border-radius: 5px;
  max-width: 862px;

  @media (max-width: 862px) {
    margin: 20px;
  }

  .modal-content {
    max-width: 100%;
  }

  .modal-header {
    padding: 15px 0 0;
    background-color: #fff;
    border: none;
    width: 100%;

    .nav {
      .nav-item {
        display: inline-block;
        position: relative;
        margin-right: 1px;

        &:after {
          content: '';
          pointer-events: none;
          position: absolute;
          display: inline-block;
          top: 0px;
          right: 0px;
          width: 100%;
          height: 100%;
          background-image: url('../images/tab-wave.png');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: 100% 0%;
        }

        .nav-link {
          background-color: $WarmDarkGray;
          border: 0;
          padding: 13px 45px 9px 37px;
          @include HeadingGreenBigger;
          color: $G165;
          cursor: pointer;

          &.active {
            background-color: $WarmLightGray;
            color: $vinosGreen;
            cursor: default;
          }
        }
      }
    }
  }

  .modal-body {
    margin: 30px 15px 15px;
    // margin: 30px auto 10px;
    // min-width: 320px;
  }

  .modal-footer {
    justify-content: center;
    padding: 30px 15px;
    background-color: $WarmDarkGray;

    .add-edit-wine-list-modal--delete-button {
      position: absolute;
      left: 20px;

    }
  }

}
