@import './Colors.scss';
@import './Fonts.scss';

$menuBreakpoint1: 1000px;
$menuBreakpoint2: 768px;

// h3 {
//   color: $vinosGreen;
//   text-transform: uppercase;
//   font-size: 22px;
//   font-weight: normal;
//   margin: 0 0 10px 0;
//   padding: 0;
// }

a.link-item, a.link-item:visited, a.link-item:active, a.link-item:hover {
  text-decoration: none;
}

.no-link {
  &, &:hover, &:visited, &:active {
    text-decoration: none;
  }
}

.listitem-subtitle {
  color: var(--text-light);
  font-size: 12px;
  line-height: 14px;

}

.listitem-title {
  color: var(--text-dark);
  font-size: 16px;
  line-height: 18px;

}

// button {
//   border: 0;
//   border-radius: 5px;
//   color: var(--button-color);
//   background: var(--button-bg);
//   box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
//   line-height: 18px;
//   font-size: 16px;
//   text-align: center;
//   padding: 8px 10px;
// }

.container {
  max-width: 1460px;
  margin-top:30px;
  @media(min-width: $menuBreakpoint2) {
    margin: 30px auto 0;
  }
}
.container-fluid {
  margin-top:30px;
}

.row-expand {
  @media (max-width: 767px) {
    margin: -30px -15px 0px -15px;

    >div {
      padding:0;
    }
  }
}

.aside {
  @media (max-width: 767px) {
    margin-bottom: 30px;
  }

  &--box {
    background-color: $WarmDarkGray;
    // margin: 0 -20px 30px;
    padding: 20px 20px 5px;

    & ~ .aside--box {
      margin-top: 30px;
    }
  }

  h3 {
    margin-bottom: 13px;
  }

  &--info-wrapper {
    background-color: #fff;
    padding: 20px;
  }

  &--list-wrapper {
    background-color: #fff;

    a, a:visited, a:hover, a:active {
      text-decoration: none;
    }
  }

  &--divider {
    margin-top: 33px;
  }
}

.col-background {
  background-color: $WarmDarkGray;
  // margin-left: -15px;
  // margin-right: -15px;
  padding: 10px 15px;

  @media (max-width: 768px) {
    // margin-left: -10px;
    // margin-right: -10px;
    padding: 10px 10px;
  }

  h3 {
    margin: 0 0 10px 0;
    @include HeadingGreenBigger;
  }
}

.btn-group {
  width: 100%;
  padding: 0 20px 19px 20px;

  .btn {
    width: 100%;
    border-color: $vinosMidPurple !important;
    @include BodySmaller;
    color: $vinosMidPurple !important;
    background-color: #fff !important;
    padding-bottom: 3px;

    &.active {
      color: #fff !important;
      background-color: $vinosMidPurple !important;
    }
  }
}

.link {
  &--caret-down {
    position: relative;
    padding-right: 17px !important;

    &:after {
      content: '';
      height: 8px;
      width: 13px;
      position: absolute;
      top: 50%;
      background-image: url('../images/caret-down.svg');
      background-size: contain;
      background-repeat: no-repeat;
      right: 0;
      @media (max-width: 767px) {
        top: 10px;
      }
    }
  }
}

.form-control {
  height: auto;
}

.error-shake {
  animation: shake-anim 0.4s 1 linear;
}

@keyframes shake-anim {
  0% {
    -webkit-transform: translate(30px);
  }
  20% {
    -webkit-transform: translate(-30px);
  }
  40% {
    -webkit-transform: translate(15px);
  }
  60% {
    -webkit-transform: translate(-15px);
  }
  80% {
    -webkit-transform: translate(8px);
  }
  100% {
    -webkit-transform: translate(0px);
  }
}

