@import 'Colors';
@import 'Fonts';

body {
  background-color: $pageBg !important;
  font-family: 'Geomanist', sans-serif;
  -webkit-font-smoothing: antialiased;
}

a, a:visited, a:active, a:hover {
  color: $vinosMidPurple;
  text-decoration: underline;
}

p {
  font-family: 'Geomanist', sans-serif;
}

b, strong {
  @include HeadingSmaller;
}

h1 {
  font-family: 'Geomanist-Medium', sans-serif;
}

h2 {
  font-family: 'Geomanist', sans-serif;
  font-weight: normal;
}

h3 {
  font-family: 'Geomanist', sans-serif;
  font-weight: normal;
}

a.link-item, a.link-item:visited, a.link-item:active, a.link-item:hover {
  text-decoration: none;
}

button {
  // display: flex;
  // padding: 10px;
  // align-items: center;
  // box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  // border-radius: 5px;
  // border: 0;
  cursor: pointer;
  border: none;
  text-align: initial;
  background-color: transparent;

  &:focus {
    outline: 0;
  }
}

figure {
  margin: 0;
}

h3 {
  @include HeadingGreenBigger;

  a, a:visited, a:hover, a:active {
    text-decoration: none;
    color: $vinosGreen;
  }
}

@font-face {
  font-family: "Geomanist-Light";
  src: url("../fonts/Geomanist-Light.woff") format("woff"), /* Modern Browsers */
  url("../fonts/Geomanist-Light.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Geomanist";
  src: url("../fonts/Geomanist-Regular.woff") format("woff"), /* Modern Browsers */
  url("../fonts/Geomanist-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Geomanist-Medium";
  src: url("../fonts/Geomanist-Medium.woff") format("woff"), /* Modern Browsers */
  url("../fonts/Geomanist-Medium.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Geomanist-Bold";
  src: url("../fonts/Geomanist-Bold.woff") format("woff"), /* Modern Browsers */
  url("../fonts/Geomanist-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Northwell";
  src: url("../fonts/Northwell.woff") format("woff"), /* Modern Browsers */
  url("../fonts/Northwell.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
