@import 'Fonts';
@import 'Colors';
.section {
    
    margin-bottom: 33px;
   
    &--header {
       
        background-size: cover;
        background-position: center;

        &--overlay {
            padding: 13px 20px 7px;
            background: rgba(255, 105, 30, 0.5);
        }
        
        &--text {
            @include HeadingGreenBigger;
            color: #fff;
        }
    }
    &--content {
        background-color: $WarmDarkGray;
        padding: 15px 20px 1px;

        & > .button {
            margin-bottom:19px;
        }
    }

}