@import 'Fonts.scss';
@import 'Colors.scss';

.profile-header {
    margin-bottom:30px;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    &--top {
        position: relative;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        
        &--overlay {
            position: absolute;
            top:0;
            width:100%;
            height:100%;
            background-color: rgba(0,0,0,0.4);

        }
    }

    &--name {
        position: relative;
        width:100%;
        padding: 33px 10px 10px;
        text-align: center;
        @include HeadingBiggerWeb;
        color: #fff;
    }

    &--profile-image {
        position: relative;
        width:88px;
        height: 88px;
        border-radius: 50%;
        // position: absolute;
        bottom:-16px;
        background-position: 50%;
        background-size: cover;
        margin:auto;
    }
    &--toolbar {
        background-color: $WarmDarkGray;
        padding: 36px 10px 15px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            text-align: center;
            margin-bottom: 1em;
        }
        &--buttons {
            display: flex;
            justify-content: space-around;
            width:100%;
            max-width: 380px;
        }
    }

}