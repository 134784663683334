.account-modal {
  background-color: #fff;
  border-radius: 10px;
  // padding: 60px;
  // position: relative;

  @media (max-width: 500px) {
    padding: 30px 20px;
  }

  &--close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}