@import 'Fonts';
@import 'Colors';

.simple-button {
  padding: 0;
  @include BodySmaller;
  color: $vinosMidPurple;
  text-decoration: underline;
  background-color: transparent;
  white-space: nowrap;

  svg {
    margin: 0 0 -3px 0.3em;
  }

  &.caret-type-none svg {
    display: none;
  }

  &.caret-type-left svg {
    transform: rotate(180deg);
  }

  &.caret-type-up svg {
    transform: rotate(-90deg) translateY(1px);
  }

  &.caret-type-down svg {
    transform: rotate(90deg) translateY(-1px);
  }
}