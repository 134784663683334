@import 'Colors';
@import 'Fonts';

.tab--big-image {

  // max-width: 500px;
  max-width: 760px;
  margin: auto;

  &--active-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 -10px 20px;

    h4 {
      @include HeadingSmaller;
      margin: 0 12px;
    }

    span {
      @include BodySmallest;
      color: $G125;
      margin: 0 10px;
    }
  }


  &--active-image {
    width: 240px;
    height: 120px;
    background-position: 50% 50%;
    background-size: cover;
    position: relative;
    margin: 0 10px;

    &--overlay {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &--upload-text {
    text-align: center;
    @include BodySmaller;
    color: $G125;
    // margin-bottom: 30px;
  }

  &--grid-text {
    @include HeadingSmallest;
  }

  &--image-grid {

    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    &--item {
      margin: 0 10px 20px;
      width: 240px;
      height: 120px;
      position: relative;
      background-position: 50% 50%;
      background-size: cover;
      cursor: pointer;

      &.active {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 10px solid rgba(0, 0, 0, 0.8);
        }
      }
    }
  }
}