.tab--links {

    &--items {
        margin-top: 20px;
        
        column-count: 3;

        &--item {
            display: block;
        }
    }
}