@import "./Colors.scss";
@import "./Fonts.scss";

.stock-button {
 
    &:disabled  {
        path {
            stroke: #1E1E1E;
        }
        span {
            color: #1E1E1E;
        }
    }
    
  &--icon {
    position: relative;
        span {
            position: absolute;
            top:11px;
            @include BodySmallest;
            color: $vinosMidPurple;
            width:100%;
            text-align: center;

            .selected & {
                color: #fff;
            }
        }
    }
    
}