@import 'Colors';
@import 'Fonts';

.tab--wines {
  display: flex;

  &--winelist {
    width: 100%;
    margin-right: 20px;
    overflow: auto;
    max-height: 600px;

    > p {
      @include BodySmaller;
      color: $G165;
      margin: 0 0 10px 35px;
    }

    &--item {
      display: flex;
      align-items: center;
      margin-top: 4px;
      cursor: move;

      > div {
        display: block;
        width: 100%;
      }

      &--delete-button {
        cursor: pointer;
        padding: 10px 10px 10px 0;
      }

      &--active {
        pointer-events: auto !important;
        z-index: 1052;

        .tab--wines--winelist--item--wrapper {
          box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.5);
        }
      }

      &--comment {
        background: #fff;
        padding: 5px 15px 15px;
        min-height: 60px;
        position: relative;

        &--line {
          border-top: 1px solid $G225;
          margin: 0 0 10px;
        }

        span {
          display: inline-block;
          width: calc(100% - 35px);

        }

        &--edit-button {
          right: 15px;
          bottom: 15px;
          position: absolute;
          cursor: pointer;
        }
      }

      // .wineitem {
      //     margin-left: 15px;
      // }
    }
  }

  &--search {
    width: 100%;
    margin-left: 20px;


    h3 {
      @include BodySmaller;
      color: $vinosGreen;
    }

    .hits--item--add {
      cursor: pointer;
    }

    .ais-Pagination {
      margin: 20px 44px 0px 0;

      &-list {
        display: flex;
        justify-content: space-evenly;
      }

      &-item {
        padding: 10px 0;
      }
    }
  }

  &--modal-comment {
    .form-group {
      textarea {
        height: 10em;
      }

      > span {
        width: 100%;
        text-align: center;
        display: block;
        color: white;
        font-size: 14px;
        margin: -27px 0 0 0;
        color: #323232;
        background-color: rgba(225, 225, 225, 1);
        padding: 5px;
        border-radius: 0 0 5px 5px;
      }
    }
  }

}
