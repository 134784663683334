@import 'Colors';

.hits {

  &--container {

  }

  &--item {
    display: flex;
    align-items: center;
    margin-top: 4px;

    &:hover, &:active, &:visited {
      text-decoration: none;
    }

    &--add {
      padding: 10px;
    }
  }
}