@import 'Colors';
@import 'Fonts';

.contentitem--container {
  background-color: #fff;
  margin-bottom: 20px;
}

.contentitem--image--container {
  position: relative;
  width: 100%;
  background-color: #999
}

.contentitem--image {
  width: 100%;
  height: auto;
  display: block;
}

.contentitem--image-placeholder {
  width: 100%;
  padding-top: 62.5%;
  background-color: #f9f9f9;
  display: block;
}

.contentitem--category {
  position: absolute;
  top: 0;
  right: 0;
  background: url(../images/tagshape.svg) no-repeat left center;
  background-size: contain;
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
}

.contentitem--category > span {
  display: block;
  background: $primaryBg;
  padding: 10px 20px 10px 0;
  margin-left: 30px;
}

.contentitem--header-title {
  position: absolute;
  left: 30px;
  bottom: 20px;
  right: 30px;
  font-size: 60px;
  line-height: 50px;
  color: #fff;
  @include ScriptFont;
}

.contentitem--content--container {
  padding: 30px 20px;
}

.contentitem--title {
  @include HeadingBiggestWeb;
  margin: 0 0 14px 0;
}

.contentitem--date {
  color: $G165;
  margin: 0 0 11px 0;
}

.contentitem--body {
  white-space: pre-wrap;
  margin-bottom: 1em;
}

.contentitem--intro {
  @include BodyBiggestWeb;
}