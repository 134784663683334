@import 'Fonts';
@import 'Colors';

.footer {
  background: $primaryGradient;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px 0;
  box-sizing: border-box;
  margin-top: 30px;

  .container {
    @media (max-width:575px) and (min-width:400px) {
      max-width: 80vw;
    }
    
  }

  a, a:visited, a:active {
    color: $vinosLightPurple;
  }

  h3 {
    @include HeadingGreenBigger;
    color: #fff;

    @media (max-width: 991px) {
      font-size: 18px;
      color: #fff;
    }
  }

  p {
    color: #fff;
    @media (max-width: 991px) {
      @include BodySmallest;
      color: #fff;
    }
  }

  form {

    .form-group {
      @media (min-width: 576px) {
        max-width: 280px;
      }
    } 

    button {
      margin-top: 20px;
    }

    .email {
      border: 2px solid transparent;

      &.error-shake {
        border: 2px solid red;
      }
    }

    label {
      @include BodySmaller;
      color: #fff;
      position: relative;
      margin-left: 26px;

      .checkbox--marker {
        position: absolute;
        width: 16px;
        height: 16px;
        border: 1px solid $vinosLightPurple;
        left: -26px;
        margin-top: 2px;
        background-color: white;
        border-radius: 1px;
        background-position: 50% 50%;
        background-repeat: no-repeat;

        &.error-shake {
          border: 1px solid red;
        }
      }

      input:checked ~ .checkbox--marker {
        background-image: url('../images/checkbox-checkmark.svg');
      }


      @media (max-width: 991px) {
        @include BodySmallest;
        color: #fff;
      }

      input[type=checkbox] {
        display: none;


      }
    }
  }

  &--download {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 270px;

    a {
      margin: 0 0 10px 0;
    }
  }

  &--social-media-icon {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }
}