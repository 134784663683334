@import 'Colors';

.ais-Pagination {
  text-align: center;
  line-height: 1em;
  margin-top: 20px;

  &-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &-item {
    list-style: none;
    display: inline-block;
    padding: 12px 12.5px 10px;
    margin: 0 2.5px 5px;

    &--previousPage {
      display: block;
    }
    &--nextPage {
      display: block;
     
    }

    &--page {
      border: 1px solid $vinosLightPurple;
      border-radius: 5px;
    }

    &--disabled {
      color: #999;
      cursor: default;
      border-color: #999;
    }

    &--selected {
      font-weight: bold;
      border-color: $primaryBg;
      background-color: $primaryBg;

      .ais-Pagination-link {
        color: #fff;
      }
    }
  }

  &-link {
    text-decoration: none;
  }
}