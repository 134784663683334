@import 'Fonts';
@import 'Colors';

.winelist-header {

  &--container {
    position: relative;
  }

  &--hero {
    display: flex;

    @media (max-width: 800px) {
      flex-direction: column;
    }
    @media (max-width: 576px) {
      margin-top: 40px;
    }

    &--image {
      width: 100%;
      background-size: cover;
      background-position: 50% 50%;
      background-color: #000;

      svg {
        height: 100%;
        width: auto;
        float: right;
      }

      @media (max-width: 800px) {
        padding-top: 40%;
        background-position: 50% 10%;
        svg {
          display: none;
        }
      }
    }

    &--content {
      width: 110%;
      background-color: $vinosDeepGreen;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 34px 20px 60px;
      color: #fff;
      @media (max-width: 800px) {
        width: 100%;
      }

      h1 {
        @include HeadingBiggerWeb;
        color: #fff;
      }
    }
  }

  &--overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &--title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
  }

  &--metadata {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px 10px;
    background-color: rgba(0, 0, 0, .75);
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    span {
      display: block;
    }

  }

  &--edit-button {
    cursor: pointer;
  }


}

.winelist-items {
  .link-item {
    display: block;
    margin-top: 4px;
  }
}
