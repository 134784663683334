@import 'Fonts';
@import 'Colors';

$breakpoint: 900px;

.hero {

  &--container {
    // position: relative;
    // padding-top: 36.30%;
    display: flex;
    color: $primaryText;
    margin-top: 0;
    padding: 0;


    @media (max-width: $breakpoint) {
      flex-direction: column;
      padding: 0;
    }
  }

  &--image {
    // position: absolute;
    position: relative;
    overflow: hidden;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    background: no-repeat right center;
    background-size: cover;
    display: flex;
    align-items: center;

    @media (max-width: $breakpoint) {
      min-height: 40vh;

    }

    h1 {
      // max-width: 50%;
      @include ScriptFont;
      font-size: 72px;
      line-height: 76px;
      margin: -150px 40px 0 15%;


      @media (max-width: $breakpoint) {
        margin: 20px 20px 20px 10%;
      }
    }
  }

  &--shape {
    position: absolute;
    top: -1px;
    right: -2px;
    width: auto;
    height: 100%;
    // bottom: 0;
    // background: url(../images/heroshape.svg) no-repeat left top;
    // background-size: cover;
    @media (max-width: $breakpoint) {
      display: none;
    }
  }

  &--content {
    background-color: $orangeDark;
    width: 50%;
    padding: 40px 40px 0;
    max-width: 340px;
    box-sizing: content-box;
    flex-shrink: 0;

    @media (max-width: $breakpoint) {
      box-sizing: border-box;
      width: 100%;
      max-width: 100%;
      padding: 5% 5% 0 5%;
    }

    p {
      // @include CalloutWeb;
      color: white;
      // margin: 0;

      @media (max-width: $breakpoint) {
        font-size: 22px;
        line-height: 1.5em;
      }

    }

    .button {
      margin: 0 0 30px;
      @media (max-width: $breakpoint) {
        max-width: 350px;
      }
    }

    &--text {
      margin-bottom: 20px;
    }
  }
}