@import './Fonts';
@import './Colors';

.wine-list-widget {
    
    &--empty-item {
        // padding: 10px 17px 10px 4px;
        background: #fff;
        display: flex;
        align-items: center;
        min-height: 80px;
        margin-bottom:20px;

        &--profile {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: $G225;
            margin-left:4px;

            &.own {
                border-radius: 0;
                height: 80px;
                width: 80px;
                margin-left:0px;
            }
        }

        &--text {
            @include HeadingSmaller;
            color: $G165;
            margin: 0 16px;
        }

        &--caret {
            margin-left: auto;
            margin-right: 17px;
        }

    }
}