@import "./Colors.scss";

$menuBreakpoint1: 1000px;
$menuBreakpoint2: 768px;

.App {

  main {
    // max-width: 1460px;

    // @media(min-width: $menuBreakpoint2) {
    //   margin: 0 auto;
    // }
  }
}

.App-logo-container {
  order: 1;
  flex-basis: 138px;
  flex-grow: 0;
  flex-shrink: 0;
  // width: 138px;
  // height: 34px;
  align-self: center;
  justify-self: center;
  margin-right: 25px;

  @media (max-width: $menuBreakpoint1) {
    order: initial;
  }
  @media (max-width: $menuBreakpoint2) {
    margin-left: auto;
  }
}

.App-logo {
  width: 250px;
  display: inline-block;

  @media (max-width: 550px) {
    width: 170px;
    margin: 4px 0 0 50px;
  }
}

.App-header {
  background: $primaryGradient;
  color: $primaryText;
  // padding: 23px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.App-nav-container {
  display: flex;
  margin: 0 auto;
  align-items: center;

  @media (max-width: 550px) {
    min-height: 75px;
  }
}

.App-header > .App-nav-container > .App-nav {
  flex-basis: 33%;
  flex-grow: 1;
  // margin: 0 0 -23px 0;
  @media (max-width: $menuBreakpoint1) {
    flex-basis: auto;
  }
}

.App-nav-main {
  order: 2;
  text-transform: uppercase;

  &--hamburger {
    display:none;

    position: absolute;
    top: 27px;
    left: 20px;
    width: 25px;
    height: 25px;
    z-index: 1001;
    cursor: pointer;

    @media (max-width: $menuBreakpoint2) {
      display: block;
    }

    span {
      width:100%;
      height:2px;
      background-color: #fff;
      margin: 6px 0;
      display: block;

    }
  }

  @media (max-width: $menuBreakpoint2) {
    position: fixed;
    z-index: 100;
    width:100vw;
    height:100vh;
    left:-100vw;
    top:0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    background-color: $primaryBg;
    transition: left 0.3s ease-out;

    &.open {
      left:0vw;
    }

    a {
      padding: 20px 5px 0px 5px !important;
    }
  }
}



.App-nav a, .App-nav a:visited, .App-nav a:hover, .App-nav a:active {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  line-height: 34px;
  display: inline-block;
  padding: 25px 5px 20px 5px;
}

.App-nav a.active {
  font-weight: bold;
  border-bottom: 4px solid #fff;
}
.App-header {
  @media (max-width: $menuBreakpoint2) {
    justify-content: center;
  }
  .App-nav-account {
    order: 3;
    text-align: right;
    

    @media (max-width: $menuBreakpoint2) {
      // margin:0;
      margin-left: auto;
      flex-grow: 0;
      flex-basis: auto;
      line-height: 0;
      
      a {
        font-size: 0;
        line-height: 0;
        // padding: 5px 0 24px 0;
        width: 24px;
        height: 66px;
        background-image: url('../images/icon-gear.svg');
        background-repeat: no-repeat;
        background-position: 50% 10px;
        background-size: contain;

        &:after {
          content: none;
        } 
      }
    }
  }
}

// @media (max-width: 767px) {
//   .App-nav .App-nav-account a.active {
//     border-bottom: none;
//   }

//   .App-nav-account {
//     position: absolute;
//     top: 10px;
//     right: 10px;
//     height: 17px;
//   }

//   .App-nav a, .App-nav a:visited, .App-nav a:hover, .App-nav a:active {
//     font-size: smaller;
//     padding: 5px;
//     line-height: 20px;
//   }

//   .App-header {
//     flex-direction: column;
//     padding: 10px 10px 0 10px;
//   }

//   .App-header > .App-nav {
//     margin: 0;
//   }

//   .App-header > .App-nav-main {
//     order: 2;
//     align-self: flex-start;
//     margin-top: 23px;
//   }

//   .App-logo-container {
//     order: 1;
//     flex-basis: auto;
//     align-self: flex-start;
//   }

//   .App-logo, .App-logo-container {
//     width: 76px;
//     height: 17px;
//   }
// }
