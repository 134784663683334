@import './Colors.scss';
@import './Fonts.scss';

.my-vinos {
    &--no-auth {
        &--container {
            background-color: $primaryBg;
        }
        &--hero {
            width:100%;
            // height: 400px;
            background-image: linear-gradient(to bottom, transparent 50%, #FF691E), url('../images/account-app-header-default.jpg');
            background-size: cover;
            height: auto;
            min-height: 400px;
            display: block;
            position: relative;

            h4 {
                position: absolute;
                bottom: 0px;
                @include ScriptFont;
                font-size: 100px;
                text-align: center;
                color: #fff;
                width:100%;
            }
        }
        &--content {
            // min-height: 60vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin:auto;
            padding:20px;
            max-width:700px;
            width:100%;
            box-sizing: border-box;
            
            p {
                @include BodyBiggestWeb;
                color: white;
            }

            h2 {
                color: #fff;
                margin: 40px 0 20px;
            }

            &--grid {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin: 0 -15px;

                @media (max-width: 639px) {
                   flex-direction: column;
                   margin: 0;
                }
            
                // &>div {
                //     margin: 15px;
                // }
            }

            &--section {
                background-color: white;
                padding: 10px 28px;
                margin: 15px;
                max-width: 45%;
                @media (max-width: 639px) {
                    max-width: none;
                }
               
                h4 {
                    text-align: center;
                    @include Heading;
                    color: $primaryBg;
                    margin-bottom: 5px;
                }
                

                img {
                    width:100%;
                    height:auto;
                    display: block;
                }
            }
            
        }
        
        
    }
    
    &--teaser {
        width:100%;
        height:auto;
    }
    
}