@import './Colors.scss';

// STYLES FROM FIGMA
@mixin HeadingHuge {
  font-family: 'Geomanist-Medium', sans-serif;
  font-weight: 500;
  font-size: 50px;
  line-height: 50px;
  color: $G50;
}

.heading-huge {
  @include HeadingHuge();
}

@mixin HeadingBiggerWeb {
  font-family: 'Geomanist-Bold', sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  color: $G50;
}

@mixin HeadingBiggerWeb {
  font-family: 'Geomanist-Bold', sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  color: $G50;
}

.heading-bigger-web {
  @include HeadingBiggerWeb();
}

@mixin HeadingBiggest {
  font-family: 'Geomanist-Bold', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  color: $G50;
}

@mixin HeadingBiggestWeb {
  font-family: 'Geomanist-Bold', sans-serif;
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
  color: $G50;
}

.heading-biggest {
  @include HeadingBiggest();
}

.heading-biggest-web {
  @include HeadingBiggestWeb();
}

@mixin Heading {
  font-family: 'Geomanist-Medium', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: $G50;
}

.heading {
  @include Heading();
}

@mixin HeadingSmaller {
  font-family: 'Geomanist-Medium', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 16px;
  color: $G30;
}

@mixin HeadingSmallest {
  font-family: 'Geomanist-Medium', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 14px;
  color: $G50;
}


.heading-smaller {
  @include HeadingSmaller();
}

@mixin HeadingGreenBigger {
  font-family: 'Geomanist', sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 22px;
  text-transform: uppercase;
  color: $primaryBg;
}

.heading-green-bigger {
  @include HeadingGreenBigger();
}

@mixin HeadingGreen {
  font-family: 'Geomanist', sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  font-size: 14px;
  text-transform: uppercase;
  color: $vinosGreen;
  letter-spacing: -0.006em;
}

@mixin CalloutWeb {
  font-family: 'Geomanist-Medium', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4em;
  font-size: 30px;
  color: $G30;
}

.callout-web {
  @include CalloutWeb();
}

.heading-green-bigger {
  @include HeadingGreenBigger();
}

.heading-green {
  @include HeadingGreen();
}

@mixin price {
  font-family: 'Geomanist-Light', sans-serif;
  font-weight: 300;
  font-size: 30px;
  letter-spacing: 0.0155em;
  color: $G50;
}

.price {
  @include price();
}

@mixin BodyBiggestWeb {
  font-family: 'Geomanist', sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 26px;
  font-size: 20px;
  color: $G50;
}

.body-biggest-web {
  @include BodyBiggestWeb();
}


@mixin BodyBigger {
  font-family: 'Geomanist', sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  font-size: 18px;
  color: $G50;
}

.body-bigger {
  @include BodyBigger();
}

@mixin Body {
  font-family: 'Geomanist', sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  font-size: 16px;
  color: $G50;
}

.body {
  @include Body();
}

@mixin BodyMedium {
  font-family: 'Geomanist-Medium', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: $G50;
}

.body-medium {
  @include BodyMedium();
}

@mixin BodySmaller {
  font-family: 'Geomanist', sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  font-size: 14px;
  color: $G30;
  letter-spacing: -0.006em;
}

.body-smaller {
  @include BodySmaller();
}

@mixin BodySmallest {
  font-family: 'Geomanist', sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
  font-size: 12px;
  color: $G30;
}

.body-smallest {
  @include BodySmallest();
}

@mixin Preable {
  font-family: 'Geomanist', sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 28px;
  font-size: 22px;
  color: #000;
}

.preable {
  @include Preable();
}

// CUSTOM STYLES

@mixin ScriptFont {
  font-family: 'Northwell', cursive;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
}

.script-font {
  @include ScriptFont();
}

.label-readonly {
  color: $G125;
  display: block;
}