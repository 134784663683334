@import './Colors.scss';
@import './Fonts.scss';

.button {
  position: relative;
  display: flex;
  padding: 10px;
  align-items: center;

  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: 0;
  width: 100%;
  min-width: 100px;
  cursor: pointer;


  &.theme-white {
    background-color: #FFFFFF;
  }

  &.theme-pink {
    // background-color: $vinosMidPurple;
    background: $primaryGradient;
  }

  &.theme-green {
    background-color: $vinosGreen;
  }

  &.theme-orange {
    background-color: $primaryBg;
  }

  &:disabled {
    background-color: $G225;
    color: $G165;
    cursor: default;

    .button--label {
      color: $G165;
    }
    svg {
      path {
        fill: $G165;
      }
    }
  }


  &.trim {
    display: inline-flex;
    width: auto;
  }

  &.small {
    padding: 5px;
    min-height: 35px;
  }

  &.tiny {
    padding: 0 5px;
    min-height: 24px;
    min-width: auto;

    .button--icon {
      padding-right: 5px;

      svg {
        width: auto;
        height: 16px;
      }
    }

    .button--label {
      font-size: 12px;
      line-height: 14px;
    }

  }

  &.align-center {
    justify-content: center;
  }

  &.align-left {
    justify-content: flex-start;
  }

  &.align-right {
    justify-content: flex-end;
  }

  &--icon {
    margin: 0;
    display: inline-block;
    padding: 0 10px 0 0px;
  }

  &--label {
    // flex-grow: 1;
    @include Body;
    padding-top: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // padding-right: 10px;
    // color: $vinosMidPurple;

    .theme-white & {
      color: $vinosMidPurple;
    }

    .theme-green & {
      color: #fff;
    }

    .theme-pink & {
      color: #fff;
    }

    .theme-orange & {
      color: #fff;
    }
  }

  &--badge {
    @include Body;
    color: $G125;
    margin-left: auto;
    padding-right: 16px;
    padding-top: 2px;

    .theme-green & {
      color: #fff;
    }

    .theme-pink & {
      color: #fff;
    }
  }

  &--caret {
    // margin-bottom: -3px;
    position: absolute;
    // padding-left: 9px;
    right: 10px;
    // margin-left: auto;

    svg {
      display: block;
    }
  }
}