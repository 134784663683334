@import 'Colors';
@import 'Fonts';

.wine {

  .aside {
    @media (max-width:767px) {
      margin-top:30px
    }

    &--wine-note {

      &--header {
        display: flex;
        margin-bottom: 21px;
        justify-content: space-between;
        
        .basic-button{
          padding:0;
          svg {
            width:30px;
            height:30px;
          }
        } 
      }
  
      &--text {
        margin: 0;
      }
    }
  }

  &--basic-info {
    padding: 40px 5px;
    background-color: #fff;
    border-bottom: 1px solid $G225;

    &--divider {
      height: 4px;
      width: 100%;
      background-color: $primaryBg;
      margin: 20px 0;
    }

    &--name {
      margin: 0;
      text-transform: uppercase;
      @include HeadingBiggerWeb;
    }

    &--name2 {
      @include Heading;
      // margin:0;
    }

    &--product-image-wrapper {
      min-height: 259px;
    }

    &--product-image {

      max-width: 100%;
      max-height: 414px;
      text-align: center;

      position: absolute;
      left: 50%;
      transform: translateX(-50%);

      @media (max-width: 1060px) and (min-width: 989px),
      (max-width: 890px) and (min-width: 768px),
      (max-width: 550px) {
        position: relative;
        transform: none;
        left: auto;
      }


    }

    &--buttons-wrapper {
      width: 50%;

      @media (max-width: 1060px) and (min-width: 989px),
      (max-width: 890px) and (min-width: 768px),
      (max-width: 550px) {
        width: auto;
      }

      &--button-group {
        display: flex;
        flex-wrap: wrap;

        button {
          margin: 5px;
          flex-grow:1;
          width: 25%;
        }
      }
    }


    &--price {
      margin: 0;
      @include price;

    }

    &--packaging {
      margin: 0;
      @include BodyBigger;
      color: $G125;
    }

    &--range {
      margin: 0;
      @include BodyBigger;
      color: $G125;
    }
  }

  &--additional-info {
    padding: 40px 5px 24px;
    background-color: #fff;

    &--mini-icons {
      margin: 0 0 0 24px;

      &--icon {
        display: inline-block;
        vertical-align: top;
        line-height: 0;
        text-align: center;
        width: 17px;
        margin: 4px 7px 0 -24px;
      }

      &--label {

      }
    }

    &--pairings {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }

  

  

}

