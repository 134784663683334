.icon-small-flag {
  display: inline-block;
  width: 15px;
  height: 12px;
}

.icon-big-flag {
  display: inline-block;
  width: 48px;
  height: 38px;
}