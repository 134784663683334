@import './Colors.scss';
.save-button {

    

   
    &--icon {
        position: relative;
        width:24px;
        height:24px;
        border-radius: 50%;
        // border: 2px solid $vinosMidPurple;
        transform: translate3d(0%,0%,0);
        
        .selected & {
            transform: matrix(-0.57357, 0.81915, -0.81915, -0.57357, 0, 0);
            background-color: $vinosMidPurple;
        }

        &--lines {
            width:100%;
            height:100%;
            position: relative;
            transform: translate3d(0%,0%,0);

            .selected & {
                transform: matrix(1, 0, 0, 1, -2, -3);
            }

            :disabled & {
                opacity: 0.5;
            }

        }

        &--line1 {
            position: absolute;
            top:50%;
            left:50%;
            width:16px;
            height:2px;
            background-color: $vinosMidPurple;
            transform: translate3d(-50%,-50%,0);
            
            .selected & {
                width:14px;
                transform: matrix(1, 0, 0, 1, -6, -1);
                background-color: #fff;
            }

            :disabled & {
                background-color: $G30;
            }
        }
        &--line2 {
            position: absolute;
            top:50%;
            left:50%;
            width:2px;
            height:16px;
            background-color: $vinosMidPurple;
            transform: translate3d(-50%,-50%,0);
            // transition: background-color, 0.3s;

            .selected & {
                height: 10px;
                transform: matrix(1, 0, 0, 1, 6, 0);
                background-color: #fff;
            }

            :disabled & {
                background-color: $G30;
            }
        }
    }

    
}