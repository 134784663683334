.hamburger-menu {
    position: absolute;
    z-index: 1001;
    top:8px;
    left: 0;
    height: 75px;

    display: inline-block;
    overflow: visible;
    margin: 0;
    padding: 15px;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity,filter;
    display: none;
    @media (max-width: 768px) {
        display: inline-block;
    }

    &--box {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 30px;

        &--inner {
            top: 50%;
            display: block;
            margin-top: -2px;

            position: absolute;
            width: 30px;
            height: 3px;
            transition-timing-function: cubic-bezier(.55,.055,.675,.19);
            transition-duration: .22s;
            transition-property: transform;
            // border-radius: 4px;
            background-color: #fff;

            .is-active & {
                transition-delay: .12s;
                transition-timing-function: cubic-bezier(.215,.61,.355,1);
                transform: rotate(225deg);
            }

            &:before, &:after {
                position: absolute;
                width: 30px;
                height: 3px;
                transition-timing-function: ease;
                transition-duration: .15s;
                // transition-property: transform;
                // border-radius: 4px;
                background-color: #fff;

                display: block;
                content: "";
            }

            &:before {
                top: -10px;
                transition: top .1s ease-in .25s,opacity .1s ease-in;
                .is-active & {
                    top: 0;
                    transition: top .1s ease-out,opacity .1s ease-out .12s;
                    opacity: 0;
                }
            }
            &:after {
                bottom: -10px;
                transition: bottom .1s ease-in .25s,transform .22s cubic-bezier(.55,.055,.675,.19);
                .is-active & {
                    bottom: 0;
                    transition: bottom .1s ease-out,transform .22s cubic-bezier(.215,.61,.355,1) .12s;
                    transform: rotate(-90deg);
                    
                }
            }
        }
    }
}