.modal {
  // .modal-dialog {
  //   max-width: 400px;
  // }
  .modal-content {
    background-color: #fff;
    border: none;
    border-radius: 5px;
  }
  .modal-body {
    padding:20px 20px 30px;
    .form-group {
      button {
        max-width: 244px;
        margin: auto;
      }
    }
  }

  .textarea {
    border-radius: 5px;
  }

}