@import 'Fonts';
@import 'Colors';

.card--container {
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  &--wrapper {
    width: 100%;
    padding-top: 140%;
    position: relative;
    flex-direction: column;
    @media (max-width: 700px) {
      width: 145px;
      padding-top: 150%;
    }
  }

  &--inner-wrapper {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.card--container svg {
  position: absolute;
  top: 10px;
  right: 10px;
}

.card--image {
  border-radius: 50%;
  background: no-repeat center center;
  background-size: cover;
  width: 110px;
  height: 110px;
  flex-shrink: 0;
}

.card--title {
  @include Heading;
  text-align: center;
  color: #000;
  margin: 8px 2px 12px;
  max-width: 130px;

}

.card--company {
  @include BodyBigger;
  text-align: center;
  color: $G125;
  margin: 0;
  max-width: 130px;
}

.card--button {
  padding: 5px 0;
}