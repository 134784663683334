@import './Fonts.scss';
@import './Colors.scss';

.country--info {

  color: white;
  float: left;
  display: flex;
  width: calc(100% + 30px);
  background-size: cover;
  background-position: 50% 0%;
  margin: 0 -15px;

  &:before {
    content: '';
    display: block;
    padding-top: 160%;
    float: left;
  }

  &--content {
    margin: 113px 0 30px;
    padding: 0px 20px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.3) 16%);
    align-self: flex-end;

    img {
      margin-bottom: 30px;
    }

    h4 {
      @include ScriptFont;
      font-size: 72px;
      line-height: 74px;
      margin: 0;
    }

    p {
      @include BodyBiggestWeb;
      color: white;
      white-space: pre-line;
    }
  }
}