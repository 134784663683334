@import 'Fonts';
@import 'Colors';

.tab--publish {
  max-width: 500px;
  margin: auto;

  &--head {
    display: flex;
    justify-content: space-between;
    margin: 0 0 30px;

    h4 {
      @include HeadingSmaller;
    }
  }

  &--date {
    h5 {
      @include HeadingSmaller;
    }

    &--input {
      display: flex;
      align-items: center;
      width: 220px;
      margin-bottom: 20px;


      svg {
        flex-grow: 0;
        flex-shrink: 0;
        margin: 0px 5px 0 0;
      }

      p {
        @include BodySmaller;
        color: $vinosMidPurple;
        margin: 0 0.5em 0 0;
      }

      &--erace-button {
        cursor: pointer;
      }
    }
  }

  .publish-status-indicator {
    display: flex;
    align-items: center;

    p {
      margin: 0 0.5em;
    }

    &--dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }
}