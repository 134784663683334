@import 'Fonts';

.icon-pairings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;

  &--container {
    display: flex;

    margin: 0;
    height: 40px;
  }

  &--image {
    display: block;
  }

  &--label {
    @include BodySmallest;
  }
}