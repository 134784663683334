@import './Colors.scss';
@import './Fonts.scss';

.action-button {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    
    flex-direction: column;
    align-items: center;
    padding: 4px 3px;
    min-width:90px;
    background-color: #fff;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    &:disabled {
        opacity: 0.5;
        background-color: $G225;
        box-shadow: none;
        cursor: auto;
    }


    &--icon {
        position: relative;
        // width:24px;
        // height:24px;
        transform: translate3d(0%,0%,0);
        pointer-events: none;
    }

    &--label {
        @include BodySmaller;
        color:  $vinosMidPurple;
        display: block;
        line-height: 10px;
        margin-top: 6px;
        text-transform: uppercase;

        :disabled & {
            color:  $G30;
        }
    }
}