@import 'Colors';
@import 'Fonts';

.searchbox {
  margin-bottom: 30px;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  overflow: hidden;

  form {
    display: flex;
    flex-direction: row;
  }

  input[type=search] {
    @include Body;
    border: 0;
    -webkit-appearance: none;
    padding: 14px 15px 13px;
    flex: 1 1 auto;
  }

  button {
    background: $vinosMidPurple;
    padding: 12px 10px 13px;
    width: 40px;

    svg {
      background: $vinosMidPurple;
      stroke: #fff;
      fill: #fff;
      width: 100%;
      height: 100%;
    }
  }
}

.toggle-group {
  margin-bottom: 20px;

  .refinementlist {
    margin-bottom: 1px;
  }
}