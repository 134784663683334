@import 'Fonts';
@import 'Colors';

$breakpoint1 : 900px;
$breakpoint2 : 767px;
$breakpoint3 : 550px;

.wine-list-item-widget {
  margin-bottom: 10px;

  &--header {
    display: flex;

    @media (max-width: $breakpoint1){
      flex-direction: column;
    }
    @media (max-width: $breakpoint2){
      flex-direction: row;
    }
    @media (max-width: $breakpoint3) {
      flex-direction: column;
    }
  }

  &--hero {
    width: 50%;
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;


    @media (max-width: $breakpoint1) {
      width: 100%;
    }

    // @media (max-width: 550px) {
    //   width: 100%;
    // }

    &--overlay {
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &--share {
      position: absolute;
      z-index: 1;
      padding: 8px 16px;
      top: 0;
      left: 0;
      box-sizing: border-box;
      width: 45px;
      cursor: pointer;

      svg {
        width: 100%;
        height: auto;
      }
    }

    &--follow-button {
      position: absolute;
      z-index: 1;
      top: 8px;
      right: 10px;
    }

    &--name {
      position: relative;
      @include HeadingBiggest;
      padding: 50px 20px;
      color: #fff;
      text-align: center;
      margin: 0;
    }

    &--toolbar {
      position: absolute;
      bottom: 0;
      width: 100%;

      padding: 5px 16px 3px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.2);

      &--item {
        @include BodySmallest;
        color: white;
        text-align: center;
        text-transform: uppercase;

        svg {
          width: 11px;
          height: 11px;
          margin: -3px 0 0 2px;
        }

        &:first-child {
          text-align: left;
        }

        &:last-child {
          text-align: right;

        }
      }

    }
  }

  &--owner {
    width: 50%;
    min-height: 160px;
    display: flex;
    padding: 16px 16px 5px;
    background-color: #fff;
    
    @media (max-width: $breakpoint1) {
      width: 100%;
    }
    @media (max-width: 550px) {
      width: 100%;
    }

    figure {
      width: 68px;
      height: 68px;
      border-radius: 50%;
      background-size: cover;
      background-position: 50% 50%;
      flex-shrink: 0;
      margin: 4px 12px 0 0;
    }

    svg {
      flex-shrink: 0;
      margin-top: 4px;
    }

    &--info {
      flex-grow: 1;
      margin: 2px 0 0 0;
      display: flex;
      flex-direction: column;

      &--link {
        cursor: pointer;
        text-decoration: none;

        &:hover, &:active, &:visited {
          text-decoration: none;
        }

        svg {
          float: right;
        }
      }

      h3 {
        @include Heading;
        margin: 0;
      }

      h4 {
        @include BodySmallest;
        margin: 2px 0 0;
      }

      p {
        @include BodySmaller;
      }

      .divider {
        width: 50px;
        height: 2px;
        background: $vinosGreen;
        margin: 3px 0 6px;
      }

      .simple-button {
        align-self: flex-end;
        margin-top: auto;
      }
    }
  }

  &--edit-bar {
    padding: 10px 20px;
    text-align: right;
    background-color: $G50;

    span {
      cursor: pointer;
    }
  }

  &--toolbar {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 400px) {
      justify-content: space-evenly;
    }

    > button {
      // flex: 1 1 auto;
      margin: 10px;
      @media (max-width: 400px) {
        margin: 10px 2px;
      }
    }
  }

  &--list {
    // display: flex;
    a, a:active, a:hover, a:visited {
      text-decoration: none;
    }

    .wineitem {
      margin-top: 4px;
    }

    &--comment {
      padding: 0px 15px 10px;
      background: #fff;

      p {
        margin: 0;
        @include BodySmaller;
        color: $G125;
      }

      &--line {
        width: 100%;
        background: radial-gradient(0.50px at 50% 50%, rgba(125, 125, 125, 0.25) 0%, rgba(125, 125, 125, 0.0625) 100%);
        height: 1px;
        margin-bottom: 10px;
      }
    }

  }
}