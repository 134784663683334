@import './Fonts';
@import './Colors';
.badge {
    padding: 7px 8px 4px;
    border-radius: 24px;
    min-Width:24px;
    position: absolute;
    right: -5px;
    top: -12px;
    background-color: $vinosGreen;
    z-index: 1;

    &.inverted {
        background-color: #fff;
    }

    &--text {
        @include BodySmaller;
        color: #fff;
        line-height: 14px;
        text-align:  center;
        
        .inverted & {
            color: $vinosGreen
        }

    }
}