@import 'Colors';
@import 'Fonts';

.wineitem {

  &--container {
    display: flex;
    flex-direction: row;
    padding: 8px 10px 10px 5px;
    margin: 0;
    min-height: 80px;
    flex: 1 1 auto;
    color: #000;
    text-decoration: none;
    background-color: white;
  }

  &--image-container {
    margin-top: 2px;
    margin-right: 5px;
    width: 30px;
    overflow: hidden;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  &--image {
    max-width: 30px;
    max-height: 60px;
  }

  &--image-placeholder {
    width: 30px;
    height: 60px;
    background-color: #f9f9f9;
  }

  &--content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1 1 auto;
  }

  &--subtitle {
    @include BodySmallest;
    color: $G125;
  }

  &--title {
    @include HeadingSmallest;
    color: $G50;
    margin: 2px 0 0.5em
  }

  &--icons {
    display: flex;
    margin-top: auto;
    align-items: flex-end;
    padding-bottom: 2px;

    & > * {
      margin-right: 7px !important;
    }
  }

  &--price-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    &--volume {
      @include BodySmallest;
      color: $G125;
      text-align: right;
    }

    &--price {
      @include price;
      font-size: 16px;
      line-height: 16px;
      text-align: right;
      flex: 1 1 auto;
      margin-top: 1px;
    }

    &--article-number {
      text-align: right;
    }
  }
}