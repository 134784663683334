@import './Colors.scss';

.icon-wine-type {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0 auto;
  line-height: 0;

  > img {
    width: 100%;
    height: 100%;
  }

  &.type-red {
    background-color: $redWine;
  }

  &.type-white {
    background-color: $whiteWine;
  }

  &.type-rose {
    background-color: $roseWine;
  }

  &.type-sparkling {
    background-color: $sparklingWine;
  }

  &.type-aperitif {
    background-color: $aperitifWine;
  }

}