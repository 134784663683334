@import './Fonts.scss';
.share-modal {
  max-width: 400px;
  
  &--container {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;

    h2 {
      @include HeadingBiggerWeb;
      width:100%;
      text-align: center;
      margin-bottom: 30px;
    }
  }
}