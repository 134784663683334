@import './Fonts.scss';
@import './Colors.scss';

.account-and-app-sign {
    background-color: $primaryBg;
    header {
        position: relative;

        height: 400px;
        background-image: linear-gradient(to bottom, transparent 50%, #FF691E), url('../images/account-app-header-default.jpg');
        width: 100%;
        background-size: cover;

        img {
            width:100%;
            height:auto;
        }
        
        h4 {
            position: absolute;
            bottom: 0px;
            @include ScriptFont;
            font-size: 48px;
            text-align: center;
            color: #fff;
            width:100%;
        }
    }
    &--content {
        padding: 0 20px 20px;
        margin-top:-20px;
        color: #fff;

        p{
            margin: 20px 0;
        }

        &--download-group {
            display: flex;
            justify-content: space-between;
            img {
                width:100%;
                height:auto;
            }
        }
    }
}