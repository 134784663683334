@import 'Fonts';
@import 'Colors';

.winelist-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  background-color: #fff;
  align-items: stretch;
  justify-items: stretch;
}

.winelist-item-image-container {
  position: relative;
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  flex-grow: 0;

  .public & {
    width: 68px;
  }
}

.winelist-item-image {
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  .public & {
    width: 60px;
    height: 60px;
    margin: 4px;
    border-radius: 50%;
    overflow: hidden;
  }
}

.winelist-item--bar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .8);
  padding: 2px 5px;
  color: #fff;
  font-size: 12px;
  display: flex;
  // justify-content: center;
  justify-content: space-between;

  &.public {
    justify-content: space-between;
  }


  &--winecount {

    line-height: 0;
    margin: 1px 0 -1px;
    
    .public & {
      margin-left:auto;
    }

    &.right {
      float: right;
    }
    svg {
      margin: 0 0 3px 2px;
    }
  }

  &--fav-icon {
    margin-top: 2px;
    float: left;

    svg {
      width: 10px;
      height: 10px;
      display: block;
    }
  }


  .public & {
    background-color: transparent;
    color: $primaryBg;
    text-align: right;
    // display: flex;
    // justify-content: space-between;
  }
}

.winelist-item-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px 12px;
  flex: 1 1 auto;
}

.winelist-item-subtitle {
  text-overflow: ellipsis;
  @include BodySmallest;
  color: $G125;
}

.winelist-item-title {
  @include HeadingSmaller;
}

.winelist-item-caret {
  align-items: center;
  justify-content: center;
  display: flex;
  width:40px;

  .selected & {
    background-color: $vinosGreen;
  }
}